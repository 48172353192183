<template>
  <div class="epicentral">
    <div class="content">
      <div class="epicentral-banner">
        <picture>
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/banner_mob.jpg
            "
            media="(max-width: 767px)"
          />
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/banner_tab.jpg
            "
            media="(max-width: 1220px)"
          />
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/banner.jpg
            "
            media="(min-width: 1221px)"
          />
          <img
            class="epicentral-banner__img"
            src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/banner.jpg"
          />
        </picture>
        <div class="epicentral-banner__text">
          <div class="epicentral-banner__title">
            ВсмATTRиваемся в&nbsp;полинейропатию
          </div>
          <div class="epicentral-banner__description">
            Видео с экспертами и клинические разборы реальных пациентов помогут
            узнать больше о лабораторной и&nbsp;инструментальной диагностике,
            классификации ATTR&#8209;амилоидоза и возможностях терапии
          </div>
        </div>
      </div>
    </div>
    <Breadcrumbs :pages="[{ name: 'ВсмATTRиваемся в полинейропатию' }]" />
    <div class="content">
      <div class="epicentral__title">Материалы</div>
    </div>

    <div class="content">
      <div>
        <div class="materials-page__list">
          <a
            :href="item.href"
            :download="item.download"
            :target="item.blank ? '_blank' : '_self'"
            class="epicentral-material"
            v-for="(item, ind) in cards"
            :key="ind"
            :style="`background-color: ${item.color}`"
          >
            <img
              class="epicentral-material__background"
              v-if="item.text_preview"
              :src="item.text_preview"
            />

            <div
              class="epicentral-material__content"
              :class="{
                'epicentral-material__text': item.material_type === 'Статья',
                'epicentral-material__video': item.material_type === 'Видео',
              }"
            >
              <div class="epicentral-material__type" v-if="item.material_type_text">
                <div class="epicentral-material__type-icon">
                  <svg
                    v-if="item.material_type === 'Видео'"
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22" cy="22" r="21.5" stroke="currentColor" />
                    <rect
                      x="9.5"
                      y="13.5"
                      width="26"
                      height="18"
                      rx="0.5"
                      stroke="currentColor"
                    />
                    <path
                      d="M20 25V20C20 19.176 20.9408 18.7056 21.6 19.2L24.9333 21.7C25.4667 22.1 25.4667 22.9 24.9333 23.3L21.6 25.8C20.9408 26.2944 20 25.824 20 25Z"
                      stroke="currentColor"
                    />
                  </svg>
                  <svg
                    v-else
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22" cy="22" r="21.5" stroke="currentColor" />
                    <path d="M37 37.5V10H8L8.5 38.5" stroke="currentColor" />
                    <path
                      d="M29 17V10H30L36.5 16V17H29Z"
                      stroke="currentColor"
                    />
                    <path d="M12 24H33" stroke="currentColor" />
                    <path d="M12 28H27.1111H28" stroke="currentColor" />
                    <path d="M12 32H29.9444H31" stroke="currentColor" />
                  </svg>
                </div>
                <div class="epicentral-material__type-text">
                  <span>{{ item.material_type_text }}</span>
                </div>
              </div>
              <div class="epicentral-material__image-container">
                <img
                  class="epicentral-material__image"
                  :src="item.text_preview"
                />
              </div>
              <div class="epicentral-material__text-container">
                <div
                  class="epicentral-material__title"
                  v-html="item.title"
                ></div>
                <div
                  class="epicentral-material__speaker"
                  v-if="item.material_type === 'Видео'"
                  v-html="item.speaker"
                ></div>
                <div
                  class="epicentral-material__description"
                  v-if="item.material_type === 'Видео'"
                  v-html="item.short_description"
                ></div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/pageComponents/Breadcrumbs.vue";
import EpicentralMaterial from "../../components/pageComponents/EpicentralMaterial.vue";
export default {
  name: "NevroPromo",
  components: {
    Breadcrumbs,
    EpicentralMaterial,
  },
  data: () => ({}),
  computed: {
    cards() {
      return [
         {
          title: "Треннинг. ВсмATTRиваемся в полинейропатию",
          color: "#ffffff",
          href: "https://az-most.ru/education/vsmattrivaemsya-v-polinejropatiyu",
          blank: true,
          material_type: "Статья",
          text_preview:
            "https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/card2.jpg",
        },
        {
          title: "Методические материалы. \"Дифференциальная диагностика ATTRv--ПН\"",
          color: "#ffffff",
          href: "https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/%D0%94%D0%B8%D1%84%D1%84%D0%B5%D1%80%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F_%D0%B4%D0%B8%D0%B0%D0%B3%D0%BD%D0%BE%D1%81%D1%82%D0%B8%D0%BA%D0%B0_ATTRv_%D0%9F%D0%9D.pdf",
          blank: true,
          download: '%D0%94%D0%B8%D1%84%D1%84%D0%B5%D1%80%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F_%D0%B4%D0%B8%D0%B0%D0%B3%D0%BD%D0%BE%D1%81%D1%82%D0%B8%D0%BA%D0%B0_ATTRv_%D0%9F%D0%9D.pdf',
          material_type: "Статья",
          material_type_text: "Скачать",
           text_preview:
            "https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/card2.jpg",
        },
       
         {
          title: "Программа бесплатной молекулярно-генетической диагностики наследственного ATTR&#8209;амилоидоза",
          color: "#ffffff",
          href: "http://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/ATTR%200524%20diagnostics%20envelope%20324x229%2003.07.pdf",
          blank: true,
          download: 'ATTR%200524%20diagnostics%20envelope%20324x229%2003.07.pdf',
          material_type: "Статья",
          material_type_text: "Скачать",
           text_preview:
            "https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/card2.jpg",
        },
        {
          title: "Вернуться назад",
          color: "#ffffff",
          href: "https://amyloidosis.az-most.ru/",
          material_type: "Статья",
          text_preview:
            "https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/card3.jpg",
        },
      ];
    },
  },
  methods: {},
  async mounted() {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.epicentral {
  &-banner {
    position: relative;
    width: 100%;
    margin-top: 32px;

     @media screen and (max-width: 767px) {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        content: "";
        display: block;
      }
     }

    &__img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }

    &__text {
      position: absolute;
      top: 50%;
      left: 64px;
      transform: translateY(-50%);
      z-index: 2;
      max-width: 40%;

      @media screen and (max-width: 1219px) {
        max-width: 50%;
        left: 32px;
      }

      @media screen and (max-width: 767px) {
        max-width: calc(100% - 32px);
        left: 16px;
      }
    }

    &__title {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      color: #fff;

      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &__description {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: rgb(201, 199, 199);

      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &__title {
    margin-top: 40px;
    color: #830051;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
  }
}

.materials-page {
  &__list {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 32px;
    row-gap: 32px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 24px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 24px;
    }
  }

  &__text {
    margin-top: 32px;
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__load {
    margin: 32px auto 0;
    width: fit-content;
  }
}
</style>

<style lang="scss" scoped>
.epicentral-material {
  padding: 16px;
  border: 1px solid #d2d2d2;
  position: relative;
  cursor: pointer;
  min-height: 436px;
  transition: 0.3s;

  // &:nth-child(2) {
  //   grid-column-start: 2;
  //   grid-column-end: 4;
  //   padding: 0;

  //   @media screen and (max-width: 767px) {
  //     grid-column-start: auto;
  //     grid-column-end: auto;
  //   }

  //   .epicentral-material {
  //     &__type {
  //       position: absolute;
  //       top: 16px;
  //       left: 16px;
  //       color: #fff;

  //       &-text {
  //         color: #fff;
  //       }
  //     }

  //     &__text-container {
  //       padding: 16px;
  //       margin-top: 0;
  //     }

  //     &__title {
  //       color: #1f1f1f !important;
  //       font-family: "Roboto Slab", sans-serif;
  //       font-size: 18px;
  //       font-style: normal;
  //       font-weight: 400;
  //       line-height: 22px;
  //     }

  //     &__speaker {
  //       margin-top: 16px;
  //       margin-bottom: 16px;
  //       color: #1f1f1f;
  //       font-family: "Roboto", sans-serif;
  //       font-size: 14px;
  //       font-style: normal;
  //       font-weight: 500;
  //       line-height: 20px;
  //     }

  //     &__description {
  //       color: #1f1f1f;
  //       font-family: "Roboto", sans-serif;
  //       font-size: 16px;
  //       font-style: normal;
  //       font-weight: 400;
  //       line-height: 22px;
  //     }

  //     &__background {
  //       display: none;
  //     }

  //     &__image-container {
  //       display: block;
  //       box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  //     }
  //   }
  // }

  &:hover {
    transform: translateY(-10px);
  }

  @media screen and (max-width: 1220px) {
    min-height: 416px;
  }

  @media screen and (max-width: 767px) {
    min-height: 300px;
  }
  // 9n + 1

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__image-container {
    display: none;
  }

  &__type {
    color: #8a7098;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-text {
      margin-left: 15px;
      color: #8a7098;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */
    }
  }

  &__image-container {
    width: 100%;
    height: 208px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;

    & + .epicentral-material__content {
      color: #fff;

      .epicentral-material__type-text {
        color: #fff;
      }

      .epicentral-material__type {
        color: #fff;
      }

      .epicentral-material__title {
        color: #fff;
      }
    }
  }

  &__text {
    .epicentral-material {
      &__text-container {
        margin-top: auto;
      }

      &__title {
        color: #1f1f1f;
        font-family: "Roboto Slab", sans-serif;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
      }
    }
  }

  &__video {
    .epicentral-material {
      &__text-container {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      &__title {
        margin-top: 16px;
        margin-bottom: 16px;
        color: #590a95;
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      &__speaker {
        color: #3c4242;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }

      &__description {
        margin-top: auto;
        color: #3c4242;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
</style>